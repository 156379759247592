import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import laptop from '../assets/images/laptop.jpg';
import ScrollAnimation from 'react-animate-on-scroll';
import '../styles/animations.css';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '65px 0',
		'& h1': {
			textAlign: 'center',
			fontWeight: 300
		},
		'& hr': {
			width: '30rem',
			textAlign: 'center'
		}
	},
	projectImage: {
		boxShadow: '-9px 7px 17px -2px rgba(0,0,0,0.48)',
		transition: 'all 0.5s ease-in-out',
		'&:hover': {
			transform: 'scale(1.1)'
		}
	},
	list: {
		borderLeft: '5px solid #2196f3',
		margin: '1.5rem 0',
		lineHeight: 1.5
	},
	grid: {
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column-reverse'
		}
	},
	header: {
		opacity: 0,
		animation: '1s page-load 0.5s forwards'
	}
}));

function ProjectTechs({ techs }) {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<h1>Technologies Used:</h1>
				<hr />
			</div>
			<Container>
				<Grid className={classes.grid} container spacing={2}>
					<Grid item xs={12} sm={8} md={6}>
						<ScrollAnimation animateOnce={true} animateIn="animate__fadeInDown">
							<img
								className={classes.projectImage}
								style={{
									margin: '1.5rem 0',
									maxWidth: '100%',
									position: 'relative',
									height: 'auto'
								}}
								src={laptop}
								alt=""
							/>
						</ScrollAnimation>
					</Grid>
					<Grid item xs={12} sm={4} md={6}>
						<ScrollAnimation animateOnce={true} animateIn="animate__fadeInRight">
							<ul className={classes.list}>{techs.map((tech) => <li>{tech}</li>)}</ul>
						</ScrollAnimation>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

export default ProjectTechs;
