import React from 'react';
import Landing from '../components/Landing';
import About from '../components/About';
import Projects from '../components/Projects';
import Contact from '../components/Contact';
import SoftSkills from '../components/SoftSkills';
import TechnologicalSkills from '../components/TechnologicalSkills';
import Navbar from '../components/Navbar';
import Connect from '../components/Connect';

function Home() {
	return (
		<div>
			<Navbar isAnotherRoute={false} />
			<Landing />
			<About />
			<Projects />
			<TechnologicalSkills />
			<Connect />
			<SoftSkills />
			<Contact />
		</div>
	);
}

export default Home;
