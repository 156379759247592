import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { Element } from 'react-scroll';
import Button from '@material-ui/core/Button';
import about from '../assets/images/about.png';
import { Link as ScrollLink } from 'react-scroll';
import ScrollAnimation from 'react-animate-on-scroll';

const useStyles = makeStyles((theme) => ({
	root: {
		background: 'rgba(0, 0, 0, 0.09)',
		[theme.breakpoints.down('xs')]: {
			height: '650px',
			paddingBottom: '20px'
		},
		[theme.breakpoints.up('md')]: {
			height: '500px'
		}
	},
	container: {
		'& h1': {
			fontWeight: '300'
		},
		'& img': {
			border: '5px solid #be2835',
			marginLeft: '50px',
			borderRadius: '10px',
			marginTop: '85px',
			[theme.breakpoints.down('sm')]: {
				border: 'none',
				marginLeft: '0',
				width: '100%',
				height: '100%',
				marginTop: '0px'
			}
		},
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column'
		}
	},
	content: {
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			marginTop: '81px'
		}
	}
}));

function About() {
	const classes = useStyles();
	return (
		<div className={classes.root} id="about">
			<Element name="about">
				<Container className={classes.container}>
					<ScrollAnimation animateOnce={true} animateIn="animate__fadeInLeft" duration={1.5}>
						<div className={classes.content}>
							<h1>Who am I...</h1>

							<hr />
							<h3>Hello there! I'm Mehrdad and I'm a Software Engineer</h3>
							<p>
							I take pleasure in dedicating my leisure time to advancing my expertise in software engineering and embarking on innovative, complex projects.
							</p>
							<ScrollLink to="contact" spy={true} smooth={true} duration={500}>
								<Button variant="outlined" color="secondary">
									Find out more
								</Button>
							</ScrollLink>
						</div>
					</ScrollAnimation>
					<ScrollAnimation animateOnce={true} animateIn="animate__fadeInRight" duration={1.5}>
						<div>
							<img src={about} alt="" />
						</div>
					</ScrollAnimation>
				</Container>
			</Element>
		</div>
	);
}

export default About;
