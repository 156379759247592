import React from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import ProjectOverview from '../components/ProjectOverview';
import Contact from '../components/Contact';
import { projects } from '../data';
import ProjectTechs from '../components/ProjectTechs';

function Project() {
	const { projectURL } = useParams();
	const result = projects.filter((project) => project.projectURL === projectURL);
	const project = result[0];
	return (
		<div>
			<Navbar isAnotherRoute={true} />
			<ProjectOverview {...project} />
			<ProjectTechs {...project} />
			<Contact />
		</div>
	);
}

export default Project;
