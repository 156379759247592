import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';
import eye from '../assets/images/eye.png';
import ScrollAnimation from 'react-animate-on-scroll';
import { v4 as uuidv4 } from 'uuid';
import { projects } from '../data';

const useStyles = makeStyles((theme) => ({
	root: {
		'& h1': {
			fontWeight: '300'
		},
		'& hr': {
			width: '20rem',
			marginRight: '100%'
		}
	},
	card: {
		maxWidth: 345
	},
	media: {
		height: 190,
		zIndex: 1
	},
	eye: {
		height: 190,
		position: 'absolute',
		opacity: 0,
		backgroundPosition: 'center',
		transition: 'all 0.3s ease-in',
		zIndex: 2
	},
	overlay: {
		display: 'flex',
		justifyContent: 'center',
		position: 'absolute',
		background: 'rgba(0, 0, 0, 0.2)',
		width: '100%',
		opacity: 0,
		height: '190px',
		transition: 'all 0.3s ease-in',
		'&:hover': {
			'& img': {
				opacity: 1
			},
			opacity: 1
		},
		zIndex: 3
	},
	grid: {
		[theme.breakpoints.down('xs')]: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		}
	}
}));

function Projects() {
	const classes = useStyles();
	return (
		<Element name="projects" id="projects">
			<div style={{ paddingTop: '65px', paddingBottom: '65px' }}>
				<Container className={classes.root}>
					<ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp">
						<h1>My Latest Work</h1>
						<hr />
						<p>Some of personal projects.</p>
					</ScrollAnimation>
					<ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp">
						<Grid container spacing={2}>
							{projects.map((project) => (
								<Grid key={uuidv4()} className={classes.grid} item xs={12} sm={6} md={4} lg={3}>
									<Card className={classes.card}>
										<Link to={`/projects/${project.projectURL}`}>
											<div style={{ position: 'relative', cursor: 'pointer' }}>
												<div className={classes.overlay}>
													<img alt="project-overlay" className={classes.eye} src={eye} />
												</div>
												<CardMedia className={classes.media} image={project.image} />
											</div>
										</Link>
										<CardContent>
											<Typography gutterBottom variant="h5" component="h2">
												{project.projectName}
											</Typography>
											<Typography variant="body2" color="textSecondary" component="p">
												{`${project.description.split('.')[0]} ...`}
											</Typography>
										</CardContent>
									</Card>
								</Grid>
							))}
						</Grid>
					</ScrollAnimation>
				</Container>
			</div>
		</Element>
	);
}

export default Projects;
