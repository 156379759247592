import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/animations.css';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import ScrollAnimation from 'react-animate-on-scroll';
import '../styles/animations.css';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '65px 0',
		backgroundColor: '#2C3339',
		height: '500px',
		[theme.breakpoints.down('sm')]: {
			height: 'auto'
		},
		color: 'white',
		'& h1': {
			fontWeight: 300,
			textAlign: 'center'
		},
		'& hr': {
			width: '20rem',
			textAlign: 'cetner'
		}
	},
	container: {
		height: '100%',
		color: 'white',
		'& p': {
			lineHeight: '1.5',
			paddingLeft: '10px',
			borderLeft: '5px solid #2196f3'
		}
	},
	buttons: {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between'
	},
	button: {
		width: '48%',
		[theme.breakpoints.only('sm')]: {
			fontSize: '12px'
		}
	},
	projectImage: {
		boxShadow: '9px 7px 17px -2px rgba(0,0,0,0.48)',
		transition: 'all 0.5s ease-in-out',
		'&:hover': {
			transform: 'scale(1.1)'
		}
	},
	header: {
		opacity: 0,
		animation: '1s page-load 0.5s forwards'
	}
}));

function ProjectOverview({ description, image, githubURL, websiteURL }) {
	const classes = useStyles();
	useEffect(() => {
		window.scrollTo(0, 0);
	});
	return (
		<div className={classes.root}>
			<Container className={classes.container}>
				<div className={classes.header}>
					<h1>Overview:</h1>
					<hr />
				</div>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={4} md={6}>
						<ScrollAnimation animateOnce={true} animateIn="animate__fadeInLeft">
							<p>{description}</p>
						</ScrollAnimation>
					</Grid>
					<Grid item xs={12} sm={8} md={6}>
						<ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp">
							<img
								style={{
									margin: '1.5rem 0',
									maxWidth: '100%',
									position: 'relative',
									height: 'auto'
								}}
								className={classes.projectImage}
								src={image}
								alt="Project Homepage"
							/>
							<div className={classes.buttons}>
								<Button
									target="_blank"
									classes={{
										sizeLarge: classes.button
									}}
									href={websiteURL}
									variant="contained"
									color="primary"
									size="large"
								>
									View Project
									<FontAwesomeIcon
										size="lg"
										style={{ marginLeft: '10px' }}
										icon={faAngleDoubleRight}
									/>
								</Button>
								<Button
									target="_blank"
									classes={{
										sizeLarge: classes.button
									}}
									href={githubURL}
									variant="contained"
									color="primary"
									size="large"
								>
									View Code
									<FontAwesomeIcon
										size="lg"
										style={{ marginLeft: '10px' }}
										icon={faAngleDoubleRight}
									/>
								</Button>
							</div>
						</ScrollAnimation>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

export default ProjectOverview;
