import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import { Link as ScrollLink } from 'react-scroll';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import logo from '../assets/images/logo.png';
import { v4 as uuidv4 } from 'uuid';
import '../styles/animations.css';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100vw',
		'& a': {
			textDecoration: 'none',
			color: 'inherit'
		}
	},
	title: {
		flexGrow: 1
	},
	icon: {
		marginLeft: '10px',
		fontSize: '17px',
		position: 'relative',
		bottom: '2px'
	},
	button: {
		color: 'rgba(255, 255, 255, 0.8)',
		opacity: 0,
		animation: '2s page-load 0s forwards',
		'&:hover': {
			color: 'rgba(255, 255, 255, 1)',
			boxShadow: '0px 5px 26px 3px rgba(0,0,0,0.48)',
			transition: 'all 0.2s ease-in-out'
		}
	},
	nav: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	logo: {
		width: '68px',
		height: '64px',
		marginTop: '10px'
	}
}));

function Navbar({ isAnotherRoute }) {
	const routes = [ 'about', 'projects', 'skills', 'connect', 'contact' ];
	const [ mobileView, setMobileView ] = useState(false);
	const anchorRef = React.useRef(null);
	const [ open, setOpen ] = useState(false);
	const prevOpen = React.useRef(open);
	const classes = useStyles();
	useEffect(() => {
		const setResponsiveness = () => {
			if (window.innerWidth < 670) {
				setMobileView(true);
			} else {
				setMobileView(false);
				setOpen(false);
			}
		};
		setResponsiveness();
		window.addEventListener('resize', () => setResponsiveness());
	});

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	function handleListKeyDown(event) {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	}
	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	useEffect(
		() => {
			if (prevOpen.current === true && open === false && window.innerWidth < 602) {
				anchorRef.current.focus();
			}
			prevOpen.current = open;
		},
		[ open ]
	);

	const bigSize = (
		<div className={classes.root}>
			<AppBar style={{ background: 'rgba(0, 0, 0, 0.4)', color: 'white' }}>
				<Toolbar>
					<Typography variant="h6" className={classes.title}>
						<a href="/">
							<img src={logo} alt="logo" className={classes.logo} />
						</a>
					</Typography>
					{routes.map((route) => {
						if (isAnotherRoute) {
							return (
								<a href={`/#${route}`} key={uuidv4()}>
									<Button size="large" className={classes.button} color="inherit">
										{route}
									</Button>
								</a>
							);
						} else {
							return (
								<ScrollLink key={uuidv4()} to={route} spy={true} smooth={true} duration={600}>
									<Button size="large" className={classes.button} color="inherit">
										{route}
									</Button>
								</ScrollLink>
							);
						}
					})}
					<a href="/mehrdad_eshraghi.pdf" download>
						<Button size="large" className={classes.button} color="inherit">
							RESUME <FontAwesomeIcon className={classes.icon} icon={faDownload} />
						</Button>
					</a>
				</Toolbar>
			</AppBar>
		</div>
	);
	const smallSize = (
		<div className={classes.root}>
			<AppBar style={{ background: 'rgba(0, 0, 0, 0.4)', color: 'white' }}>
				<Toolbar>
					<div className={classes.nav}>
						<Typography variant="h5">
							<a href="/">
								<img src={logo} alt="logo" className={classes.logo} />
							</a>
						</Typography>
						<IconButton
							ref={anchorRef}
							aria-controls={open ? 'menu-list-grow' : undefined}
							aria-haspopup="true"
							onClick={handleToggle}
							style={{ padding: '12px 0' }}
						>
							<MenuIcon fontSize="large" style={{ color: 'white' }} color="inherit" />
						</IconButton>
					</div>

					<Popper
						style={{ width: '100%' }}
						open={open}
						anchorEl={anchorRef.current}
						role={undefined}
						transition
						disablePortal
					>
						{({ TransitionProps, placement }) => (
							<Grow
								{...TransitionProps}
								style={{
									transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
									transform: 'translate3d(-4px, 0, 0px)'
								}}
							>
								<Paper style={{ marginTop: '10px' }}>
									<ClickAwayListener onClickAway={handleClose}>
										<MenuList
											autoFocusItem={open}
											id="menu-list-grow"
											onKeyDown={handleListKeyDown}
										>
											{routes.map((route) => {
												if (isAnotherRoute) {
													return (
														<a href={`/#${route}`} key={uuidv4()}>
															<MenuItem onClick={handleClose}>
																{route.toUpperCase()}
															</MenuItem>
														</a>
													);
												} else {
													return (
														<ScrollLink
															key={uuidv4()}
															to={route}
															spy={true}
															smooth={true}
															duration={600}
														>
															<MenuItem onClick={handleClose}>
																{route.toUpperCase()}
															</MenuItem>
														</ScrollLink>
													);
												}
											})}
											<a href="/mehrdad_eshraghi.pdf" download>
												<MenuItem onClick={handleClose}>
													RESUME
													<FontAwesomeIcon className={classes.icon} icon={faDownload} />
												</MenuItem>
											</a>
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</Toolbar>
			</AppBar>
		</div>
	);
	return mobileView ? smallSize : bigSize;
}

export default Navbar;
