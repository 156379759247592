import React from 'react';
import Container from '@material-ui/core/Container';
import { Element } from 'react-scroll';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MailIcon from '@material-ui/icons/Mail';
import Mailto from 'reactv16-mailto';
import SocialMediaButtons from 'react-social-media-buttons';
import PhoneIcon from '@material-ui/icons/Phone';
import ScrollAnimation from 'react-animate-on-scroll';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles((theme) => ({
	root: {
		'& h1, h4': {
			fontWeight: 300
		},
		lineHeight: 1.8,
		paddingTop: '57px',
		background: 'rgba(0, 0, 0, 0.8)',
		color: 'white',
		padding: '2rem'
		// [theme.breakpoints.down('sm')]: {
		// 	textAlign: 'center'
		// }
	},
	iconContainer: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		color: 'white',
		textDecoration: 'none'
	},
	icon: {
		marginRight: '10px'
	},
	socialIcon: {
		'&:hover': {
			color: 'pink'
		}
	},
	aboutGrid: {
		[theme.breakpoints.down('md')]: {
			paddingBottom: '0 !important'
		}
	},
	infoGrid: {
		[theme.breakpoints.down('md')]: {
			paddingTop: '0 !important'
		}
	}
}));

function Contact() {
	const classes = useStyles();
	return (
		<Element name="contact" id="contact">
			<div className={classes.root}>
				<Container>
					<Grid container spacing={3}>
						<Grid item xs={12} md={8} className={classes.aboutGrid}>
							<ScrollAnimation key={uuidv4()} animateOnce={true} animateIn="animate__fadeIn" duration={2}>
								<h1>About Me</h1>
								<hr />
								<h4>
								I am Mehrdad, a dedicated Software Engineer with a strong educational background.
								My journey began with my admission to Isfahan University of Technology, one of the premier institutions in my country, where I pursued a degree in Computer Engineering.
								It was during this time that I discovered my passion for software engineering, and since then, I have been committed to continuous improvement in my craft.
								Currently, I am pursuing a Master's degree in Computer Science at McMaster University, further deepening my knowledge and expertise in the field.
								Beyond my academic pursuits, I dedicate my leisure time to expanding my understanding of Software Engineering and actively engaging in collaborative endeavors to develop intricate and challenging projects.
								</h4>
								<SocialMediaButtons
									className={classes.socialIcon}
									links={[
										'https://www.linkedin.com/in/mehrdad-eshraghi',
										'https://github.com/MehrdadEshraghi',
										'mailto:mehrdad4502@yahoo.com'
									]}
									buttonStyle={{
										width: '50px',
										height: '0px',
										margin: '0',
										backgroundColor: 'transparent'
									}}
									iconStyle={{ color: '#ffffff' }}
									openNewTab={true}
								/>
							</ScrollAnimation>
						</Grid>
						<Grid item xs={12} md={4} className={classes.infoGrid}>
							<ScrollAnimation key={uuidv4()} animateOnce={true} animateIn="animate__fadeIn" duration={2}>
								<h1>Say hello:</h1>
								<h4 className={classes.iconContainer}>
									<LocationOnIcon className={classes.icon} /> Address: Hamilton, Ontario, Canada
								</h4>
								<h4>
									<Mailto className={classes.iconContainer} email="mehrdad4502@yahoo.com">
										<MailIcon className={classes.icon} /> Email: mehrdad4502@yahoo.com
									</Mailto>
								</h4>
								<h4 className={classes.iconContainer}>
									<PhoneIcon className={classes.icon} /> Phone: (365) 833-6010
								</h4>
							</ScrollAnimation>
						</Grid>
					</Grid>
				</Container>
			</div>
		</Element>
	);
}

export default Contact;
