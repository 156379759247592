import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { Element } from 'react-scroll';
import SocialMediaButtons from 'react-social-media-buttons';
import ScrollAnimation from 'react-animate-on-scroll';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '70px 0',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column'
		},
		'& h1': {
			fontWeight: 300
		},
		'& hr': {
			width: '350px',
			marginRight: '100%'
		}
	}
}));

function Connect() {
	const classes = useStyles();
	return (
		<Element name="connect" id="connect">
			<Container>
				<div className={classes.root}>
					<ScrollAnimation key={uuidv4} animateOnce={true} animateIn="animate__wobble">
						<div>
							<h1>Connect with me?</h1>
							<hr />
							<p>Want to get in touch? I share my passion for development anywhere I can.</p>
						</div>
					</ScrollAnimation>
					<ScrollAnimation key={uuidv4()} animateOnce={true} animateIn="animate__backInRight">
						<SocialMediaButtons
							key={uuidv4()}
							links={[
								'https://www.linkedin.com/in/mehrdad-eshraghi/',
								'https://github.com/MehrdadEshraghi',
								'mailto:mehrdad4502@yahoo.com'
							]}
							buttonStyle={{
								width: '90px',
								height: '90px',
								margin: '0px 12px',
								backgroundColor: '#cce7ff',
								borderRadius: '20%'
							}}
							iconStyle={{ color: '#3f51b5' }}
							openNewTab={true}
						/>
					</ScrollAnimation>
				</div>
			</Container>
		</Element>
	);
}

export default Connect;
