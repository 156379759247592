import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Element } from 'react-scroll';
import {
	faNode,
	faGitAlt,
	faJsSquare,
	faJenkins,
	faPython,
	faLinux,
	faDocker,
	faGolang,
	faAws
} from '@fortawesome/free-brands-svg-icons';
import {
	faCogs,
	faDatabase,
	faCode,
	faBrain,
	faDesktop,
	faLaptopCode,
	faTachometerAlt,
	faVial
} from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({
	root: {
		background: 'rgba(0,0,0,0.09)',
		'& h1': {
			fontWeight: 300
		},
		'& hr': {
			width: '33%'
		},
		textAlign: 'center',
		padding: '2rem 0',
		margin: '2rem 0',
		paddingTop: '65px',
		paddingBottom: '65px'
	},
	button: {
		width: '100%',
		cursor: 'default',
		'&:hover': {
			background: 'white',
			color: '#3f51b5'
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '0.8rem'
		}
	},
	icon: {
		marginLeft: '0.5rem'
	},
	grid: {
		marginBottom: '0.5rem'
	}
}));

function TechnologicalSkills() {
	const classes = useStyles();
	return (
		<Element name="skills" id="skills">
			<div className={classes.root}>
				<Container>
					<h1>Technological Skills</h1>
					<hr style={{marginBottom: "30px"}} />
					<Grid container spacing={1}>
						<Grid className={classes.grid} item xs={6} md={6}>
							<Button color="primary" variant="contained" className={classes.button}>
							JavaScript/TypeScript <FontAwesomeIcon className={classes.icon} icon={faJsSquare} />
							</Button>
						</Grid>
						<Grid className={classes.grid} item xs={6} md={6}>
							<Button color="primary" variant="contained" className={classes.button}>
								Node.JS <FontAwesomeIcon className={classes.icon} icon={faNode} />
							</Button>
						</Grid>
						<Grid className={classes.grid} item xs={6} md={6}>
							<Button color="primary" variant="contained" className={classes.button}>
								Express.JS <FontAwesomeIcon className={classes.icon} icon={faDesktop} />
							</Button>
						</Grid>
						<Grid className={classes.grid} item xs={6} md={6}>
							<Button color="primary" variant="contained" className={classes.button}>
								MongoDB <FontAwesomeIcon className={classes.icon} icon={faDatabase} />
							</Button>
						</Grid>
						<Grid className={classes.grid} item xs={6} md={4}>
							<Button color="primary" variant="contained" className={classes.button}>
								PostgreSQL <FontAwesomeIcon className={classes.icon} icon={faDatabase} />
							</Button>
						</Grid>
						<Grid className={classes.grid} item xs={6} md={4}>
							<Button color="primary" variant="contained" className={classes.button}>
								Jenkins <FontAwesomeIcon className={classes.icon} icon={faJenkins} />
							</Button>
						</Grid>
						<Grid className={classes.grid} item xs={6} md={4}>
							<Button color="primary" variant="contained" className={classes.button}>
								Python <FontAwesomeIcon className={classes.icon} icon={faPython} />
							</Button>
						</Grid>
						<Grid className={classes.grid} item xs={3} md={3}>
							<Button color="primary" variant="contained" className={classes.button}>
								C/C++ <FontAwesomeIcon className={classes.icon} icon={faLaptopCode} />
							</Button>
						</Grid>
						<Grid className={classes.grid} item xs={3} md={3}>
							<Button color="primary" variant="contained" className={classes.button}>
								Docker <FontAwesomeIcon className={classes.icon} icon={faDocker} />
							</Button>
						</Grid>
						<Grid className={classes.grid} item xs={3} md={3}>
							<Button color="primary" variant="contained" className={classes.button}>
								AWS <FontAwesomeIcon className={classes.icon} icon={faAws} />
							</Button>
						</Grid>
						<Grid className={classes.grid} item xs={3} md={3}>
							<Button color="primary" variant="contained" className={classes.button}>
								Git <FontAwesomeIcon className={classes.icon} icon={faGitAlt} />
							</Button>
						</Grid>
						<Grid className={classes.grid} item xs={3} md={3}>
							<Button color="primary" variant="contained" className={classes.button}>
								Linux <FontAwesomeIcon className={classes.icon} icon={faLinux} />
							</Button>
						</Grid>
						<Grid className={classes.grid} item xs={3} md={3}>
							<Button color="primary" variant="contained" className={classes.button}>
								Mocha/Chai <FontAwesomeIcon className={classes.icon} icon={faVial} />
							</Button>
						</Grid>
						<Grid className={classes.grid} item xs={3} md={3}>
							<Button color="primary" variant="contained" className={classes.button}>
								TensorFlow <FontAwesomeIcon className={classes.icon} icon={faBrain} />
							</Button>
						</Grid>
						<Grid className={classes.grid} item xs={3} md={3}>
							<Button color="primary" variant="contained" className={classes.button}>
								Golang <FontAwesomeIcon className={classes.icon} icon={faCode} />
							</Button>
						</Grid>
						<Grid className={classes.grid} item xs={6} md={6}>
							<Button color="primary" variant="contained" className={classes.button}>
								Machine Learning <FontAwesomeIcon className={classes.icon} icon={faCogs} />
							</Button>
						</Grid>
						<Grid className={classes.grid} item xs={6} md={6}>
							<Button color="primary" variant="contained" className={classes.button}>
								Data Structures <FontAwesomeIcon className={classes.icon} icon={faTachometerAlt} />
							</Button>
						</Grid>
					</Grid>
				</Container>
			</div>
		</Element>
	);
}

export default TechnologicalSkills;
