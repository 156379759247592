import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './routes/Home';
import Project from './routes/Project';
import 'animate.css/animate.min.css';
import './App.css';

function App() {
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/" render={(routProps) => <Home {...routProps} />} />
				<Route exact path="/projects/:projectURL" render={(routProps) => <Project {...routProps} />} />
			</Switch>
		</BrowserRouter>
	);
}

export default App
