import colorPalette from './assets/images/color-palette.png';
import emaily from './assets/images/emaily.png';
export const projects = [
	{
		projectName: 'Emaily',
		image: emaily,
		projectURL: 'emaily',
		description:
			'A MERN stack application designed for the purpose of gathering user feedback through email surveys. With this application, users can create surveys and distribute them to their audience, soliciting feedback on their services.',
		githubURL: 'https://github.com/MehrdadEshraghi/emaily',
		websiteURL: 'https://peaceful-bastion-07664.herokuapp.com/',
		techs: [
			'Node.JS',
			'Express.JS',
			'React',
			'React-Router',
			'Redux',
			'Redux-Form',
			'Redux-Thunk',
			'MongoDB',
			'Passport',
			'Material-UI',
			'Materialize-CSS',
			'JSS',
			'Stripe',
			'SendGrid API'
		]
	},
	{
		projectName: 'Color Palette',
		image: colorPalette,
		projectURL: 'color-palette',
		description: `A React-based emulation of the Flat UI Colors palette, enhanced with additional functionalities such as the ability to generate custom color palettes. You can effortlessly explore a spectrum of colors, each with various shades, and conveniently copy your preferred selections in HEX, RGB, or RGBA formats. Furthermore, you have the flexibility to fashion your unique color palette and arrange the colors to suit your preferences.`,
		githubURL: 'https://github.com/MehrdadEshraghi/color-palette',
		websiteURL: 'https://colorpalette.vercel.app/',
		techs: [ 'React', 'React-Router', 'Material-UI', 'JSS' ]
	}
];
