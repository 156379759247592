import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import CodeIcon from '@material-ui/icons/Code';
import Grid from '@material-ui/core/Grid';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PeopleIcon from '@material-ui/icons/People';
import ScrollAnimation from 'react-animate-on-scroll';

const useStyles = makeStyles({
	root: {
		'& h1, p': {
			fontWeight: 300,
			textAlign: 'center'
		},
		'& h3': {
			fontWeight: 400
		},
		'& p, h3': {
			marginTop: 0
		},
		'& hr': {
			width: '33%',
			marginBottom: '1rem'
		},
		'& p': {
			lineHeight: 1.5
		},
		background: 'rgba(0, 0, 0, 0.09)',
		padding: '4rem',
		marginTop: '2rem'
	},
	icon: {
		textAlign: 'center'
	},
	skill: {
		'& svg': {
			fontSize: '5rem',
			transition: 'all 0.5s ease-in'
		},
		'& svg:hover': {
			transform: 'scale(1.3)'
		},
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column'
	}
});

function SoftSkills() {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Container>
				<ScrollAnimation animateOnce={true} animateIn="animate__fadeIn" duration={2}>
					<h1>Why work with me?</h1>
					<hr />
					<p>There are many advantages to working with me. Here are a few:</p>
				</ScrollAnimation>
				<Grid container spacing={3}>
					<Grid item xs={12} md={4}>
						<ScrollAnimation animateOnce={true} animateIn="animate__fadeIn" duration={2} delay={500}>
							<div className={classes.skill}>
								<CodeIcon color="primary" fontSize="inherit" />
								<h3>Passionate about development</h3>
								<p>Strongly advocate that the most exceptional accomplishments are achieved by individuals who are deeply passionate about their work.</p>
							</div>
						</ScrollAnimation>
					</Grid>
					<Grid item xs={12} md={4}>
						<ScrollAnimation animateOnce={true} animateIn="animate__fadeIn" duration={2} delay={600}>
							<div className={classes.skill}>
								<MenuBookIcon color="primary" fontSize="inherit" />
								<h3>Quick learner</h3>
								<p>
									Possess a high level of confidence in my capacity to acquire new skills and effectively implement them in operational contexts.
								</p>
							</div>
						</ScrollAnimation>
					</Grid>
					<Grid item xs={12} md={4}>
						<ScrollAnimation animateOnce={true} animateIn="animate__fadeIn" duration={2} delay={700}>
							<div className={classes.skill}>
								<PeopleIcon color="primary" fontSize="inherit" />
								<h3>Adaptable</h3>
								<p>
									Enthusiastic about acquiring new knowledge and skills. One of my greatest assets is my adaptability and readiness to align with your technology stack.
								</p>
							</div>
						</ScrollAnimation>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

export default SoftSkills;
