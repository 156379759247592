import React from 'react';
import galaxy from '../assets/images/galaxy.jpg';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Link as ScrollLink } from 'react-scroll';
import '../styles/animations.css';

const useStyles = makeStyles((theme) => ({
	root: {
		zIndex: 2,
		display: 'flex'
	},
	overlay: {
		background: 'black',
		position: 'absolute',
		right: 0,
		width: '100vw',
		height: '100vh',
		opacity: 0.4,
		zIndex: 3
	},
	image: {
		backgroundImage: `url(${galaxy})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		width: '100vw',
		height: '100vh',
		position: 'absolute'
	},
	info: {
		'& h1': {
			fontWeight: 200,
			[theme.breakpoints.down('xs')]: {
				fontSize: '60px'
			},
			fontSize: '5rem',
			animation: '2s letter 0.5s forwards',
			opacity: 0,
			margin: '1rem',
			textAlign: 'center'
		},
		'& a': {
			textDecoration: 'none',
			color: 'inherit'
		},
		'& i': {
			fontSize: '3rem'
		},
		minHeight: '650px',
		color: 'white',
		zIndex: 4,
		display: 'flex',
		animation: '2s page-load 0.5s forwards',
		opacity: 0,
		width: '100vw',
		flexDirection: 'column',
		height: '100vh',
		justifyContent: 'center',
		alignItems: 'center'
	},
	paragraph: {
		background: 'rgba(0, 0, 0, .5)',
		padding: '0.5rem',
		marginBottom: '1.5rem',
		textAlign: 'center'
	},
	icon: {
		fontSize: '6rem',
		animation: '2s rotate 0.5s forwards',
		'&:hover': {
			cursor: 'pointer'
		}
	},
	lastName: {
		[theme.breakpoints.down('sm')]: {
			animation: '2s less-spacing 0s forwards'
		}
	}
}));

function Land() {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<div className={classes.overlay} />
			<div className={classes.image} />
			<div className={classes.info}>
				<h1 className={classes.name}>
					<span className={classes.firstName}>Mehrdad </span>
					<span className={classes.lastName}>Eshraghi</span>
				</h1>
				<h2>Let's code for a better life</h2>
				<div className={classes.paragraph}>
					<p>
					Consistently seeking new opportunities for project involvement and a coding partner <br /> Feel free to reach out
					</p>
				</div>
				<ScrollLink to="contact" spy={true} smooth={true} duration={700}>
					<Button variant="contained" color="primary">
						Get in touch
					</Button>
				</ScrollLink>
				<ScrollLink to="about" spy={true} smooth={true} duration={700}>
					<KeyboardArrowDownIcon className={classes.icon} />
				</ScrollLink>
			</div>
		</div>
	);
}

export default Land;
